import './App.css';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


function App() {
  return (
    <SwaggerUI url="/newbreedhub.json" />
  );
}

export default App;

//export default App = () => <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" />
